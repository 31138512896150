@font-face {
	font-family: 'C-B';
	src: local('Comfortaa-Bold.ttf'),
		url(./fonts/Comfortaa-Bold.ttf) format('truetype');
}
@font-face {
	font-family: 'C-L';
	src: local('Comfortaa-Light.ttf'),
		url(./fonts/Comfortaa-Light.ttf) format('truetype');
}
@font-face {
	font-family: 'C-M';
	src: local('Comfortaa-Medium.ttf'),
		url(./fonts/Comfortaa-Medium.ttf) format('truetype');
}
@font-face {
	font-family: 'C-R';
	src: local('Comfortaa-Regular.ttf'),
		url(./fonts/Comfortaa-Regular.ttf) format('truetype');
}
@font-face {
	font-family: 'C-SB';
	src: local('Comfortaa-SemiBold.ttf'),
		url(./fonts/Comfortaa-SemiBold.ttf) format('truetype');
}
@font-face {
	font-family: 'DM-I';
	src: local('DMSerifDisplay-Italic.ttf'),
		url(./fonts/DMSerifDisplay-Italic.ttf) format('truetype');
}
@font-face {
	font-family: 'DM-R';
	src: local('DMSerifDisplay-Regular.ttf'),
		url(./fonts/DMSerifDisplay-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'PFD-B';
	src: local('PlayfairDisplay-Black.ttf'),
		url(./fonts/PlayfairDisplay-Black.ttf) format('truetype');
}

:focus {
	outline: 0;
}
html {
	scroll-behavior: smooth;
}
body {
	font-size: 20px;
	font-family: C-R;
	line-height: 1.4;
}

@media (max-width: 600px) {
	body {
		font-size: 16px;
	}
}

a {
	text-decoration: none !important;
}

strong {
	font-family: C-B;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	-webkit-appearance: none;
	-moz-appearance: none;
}

input[type='search'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

textarea {
	overflow: auto;
	vertical-align: top;
	resize: vertical;
}

[hidden] {
	display: none;
}

a:active,
a:hover {
	outline: 0;
}

img {
	border: 0;
	-ms-interpolation-mode: bicubic;
	vertical-align: middle;
	max-width: 100%;
	width: auto;
}

.align-left {
	text-align: left;
}

.regular-text {
	font-family: C-R !important;
	color: #666d7a;
}

.mobile-only {
	display: none !important;
}

@media (max-width: 992px) {
	.mobile-only {
		display: flex !important;
	}
	.mobile-only.servicemobile {
		flex-direction: column;
	}
	.mobile-only.servicemobile strong {
		width: fit-content;
	}
	.mobile-none {
		display: none !important;
	}
}
